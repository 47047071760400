@tailwind base;
@tailwind components;
@tailwind utilities;
html{
    -webkit-text-size-adjust: 100%;
    font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
}
p{
    font-family: PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial;
    -webkit-font-smoothing: antialiased;
}
